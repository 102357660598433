<template>
  <Modal class="modalHireds" @submit="$emit('submit')" @cancel="$emit('cancel')">
    <template v-slot:header>
      <div class="modalHireds__container header">
        <div class="modalHireds__icon">?</div>
        <h2 class="modalHireds__title">Contrato</h2>
      </div>
    </template>
    <template v-slot:body>
      <div class="hired-contract-decision modalHireds__container body">
        <div padding-bottom>
          <span>
            El registro de la modelo
            <b>{{ modelName }}</b>
            será enviado a:
          </span>
        </div>
        <ul class="modalHireds__radios">
          <li class="modalHireds__radio">
            <input
              type="radio"
              id="spswaiver"
              name="superselectHired"
              value="waiver"
              @input="(isWaiverOption = true), (isEndContractOption = false), (isDeserter = false)"
            />
            <div class="modalHireds__selectContainer">
              <label for="spswaiver">Renuncias</label>
              <Select class="modalHireds__select selectCustomize" :options="waiverOptions" v-if="isWaiverOption"></Select>
            </div>
          </li>
          <li class="modalHireds__radio">
            <input
              type="radio"
              id="spsendContract"
              name="superselectHired"
              value="endContract"
              @input="(isWaiverOption = false), (isEndContractOption = true), (isDeserter = false)"
            />
            <div class="modalHireds__selectContainer">
              <label for="spsendContract">Cancelación de contrato</label>
              <Select class="modalHireds__select selectCustomize" :options="endContractOptions" v-if="isEndContractOption"></Select>
            </div>
          </li>
          <li class="modalHireds__radio">
            <input
              type="radio"
              id="spsisDeserter"
              name="superselectHired"
              value="deserter"
              @input="(isWaiverOption = false), (isEndContractOption = false), (isDeserter = true)"
            />
            <div class="modalHireds__selectContainer">
              <label for="spsisDeserter">Desertoras</label>
              <Select class="modalHireds__select selectCustomize" :options="deserterOptions" v-if="isDeserter"></Select>
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-slot:footer></template>
  </Modal>
</template>

<script>
export default {
  components: {
    Iconic: () => import("@/plugins/iconic/iconic.vue"),
    Modal: () => import("./Modal.vue"),
    Select: () => import("@/components/inputs/Select.vue"),
  },
  props: { modelName: { type: String, default: "" } },
  data() {
    return {
      isWaiverOption: false,
      isEndContractOption: false,
      isDeserter: false,
    };
  },
  computed: {
    waiverOptions: function () {
      const reason = this.$global.dictionary.waiverReason;
      return Object.keys(reason).map((key) => {
        return { value: key, text: reason[key] };
      });
    },
    endContractOptions: function () {
      const reason = this.$global.dictionary.endContractReason;
      return Object.keys(reason).map((key) => {
        return { value: key, text: reason[key] };
      });
    },
    deserterOptions: function () {
      return [
        {
          value: "deserter",
          text: "No localizada",
        },
      ];
    },
  },
};
</script>

<style lang="scss">
.modalHireds {
  input[name="superselectHired"] {
    margin-top: 5px;
    align-self: flex-start;
  }
  &__container {
    @include Flex(column);
    &.header {
      padding: 0 1.8em;
      margin-top: $mpadding * 1.5;
      gap: $mpadding * 2;
    }
    &.body {
      font-size: 1.125em;
      text-align: center;
      margin-bottom: 12px;
    }
  }
  &__icon {
    width: 88px;
    height: 88px;
    @include Flex(row);
    border: 4px solid #c9dae1;
    border-radius: 100%;
    font-size: 3.75em;
    color: $icon_question_modal;
  }
  &__title {
    font-size: 1.875em;
    font-weight: 600;
  }
  &__radios,
  &__selectContainer {
    @include Flex(column, flex-start, flex-start);
  }
  &__radios {
    width: auto;
    margin: auto;
    gap: $mpadding;
    text-align: start;
  }
  &__radio {
    @include Flex(row, flex-start);
    gap: $mpadding;
  }
  &__selectContainer {
    gap: 10px;
  }
  &__select {
    width: 250px;
    color: $letter_modal;
  }
  @media screen and (min-width: $pocket_width) {
    &__container {
      &.body {
        padding: 0 1.6em;
      }
    }
  }
}
</style>
